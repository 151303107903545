import React, { useState } from "react";
import { Project } from "../models/Project";
import { ProjectService } from "../../api";
import { Button, TextField } from "@material-ui/core";
import jiraLogo from "url:../img/jira-teamcoda.png";
import digitaleFactuurLogo from "url:../img/digitalefactuur.png";
import * as classes from "./CreateInvoice.less";
import { useSnackbar } from "notistack";

interface CreateInvoiceProps {
  project: Project;
}

function firstDayOfMonth(project: Project): Date {
  const date = project.lastInvoice ?? new Date();
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

export default function CreateInvoice({
  project,
}: CreateInvoiceProps): JSX.Element {
  const [start, setStart] = useState(() => firstDayOfMonth(project));
  const [end, setEnd] = useState(() => {
    const date = firstDayOfMonth(project);
    return new Date(date.getFullYear(), date.getMonth() + 1, 1);
  });

  const [urls, setUrls] =
    useState<{
      digitaleFactuurUrl: string;
      worklogsUrl: string | null;
    } | null>(null);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  async function invoice() {
    setLoading(true);
    try {
      const output = await ProjectService.invoice(project.url, {
        end: end.toISOString(),
        start: start.toISOString(),
      });
      setUrls({
        digitaleFactuurUrl: output.digitalefactuur_url,
        worklogsUrl: output.worklogs_url,
      });
    } catch (e) {
      enqueueSnackbar(`Fout bij factuur maken: ${e}`, { variant: "error" });
    } finally {
      setLoading(false);
    }
  }
  return (
    <React.Fragment>
      <div className={classes.input}>
        <TextField
          label="Vanaf"
          type="datetime-local"
          value={start?.toDatetimeLocalString()}
          onChange={(e) => setStart(new Date(e.target.value))}
        />
        <TextField
          label="Tot"
          type="datetime-local"
          value={end?.toDatetimeLocalString()}
          onChange={(e) => setEnd(new Date(e.target.value))}
        />
        <Button
          variant="contained"
          color="primary"
          disabled={urls !== null || loading}
          onClick={invoice}
        >
          Factuur maken
        </Button>
      </div>

      <div className={classes.output}>
        {urls?.digitaleFactuurUrl ? (
          <Button
            variant="contained"
            component="a"
            href={urls.digitaleFactuurUrl}
          >
            <img src={digitaleFactuurLogo} alt="Digitale Factuur" />
            Digitale factuur
          </Button>
        ) : null}
        {urls?.worklogsUrl ? (
          <Button variant="contained" component="a" href={urls.worklogsUrl}>
            <img src={jiraLogo} alt="JIRA" />
            JIRA Worklogs
          </Button>
        ) : null}
      </div>
    </React.Fragment>
  );
}
