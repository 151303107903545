import { Button, Grid, Paper } from "@material-ui/core";
import React, { FormEvent, useEffect, useState } from "react";
import { ProjectService } from "../../api";
import { RouteComponentProps } from "react-router";
import clsx from "clsx";
import * as classes from "./Project.less";
import ProjectForm from "./ProjectForm";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import { SnackbarKey, useSnackbar } from "notistack";
import OpenHours from "./OpenHours";
import JiraSynchronization from "./JiraSynchronization";
import { Project, ProjectFromAPI, ProjectToAPI } from "../models/Project";
import CreateInvoice from "./CreateInvoice";

interface ProjectDetailProps
  extends RouteComponentProps<
    { projectUrl: string },
    {},
    { project?: Project }
  > {}

export default function ProjectDetail(props: ProjectDetailProps): JSX.Element {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [jiraServer, setJiraServer] = useState(
    props.location.state?.project?.jira ?? false
  );

  const [project, setProject] = useState<Project | null>(
    props.location.state?.project ?? null
  );
  const [refreshIndex, setRefreshIndex] = useState(0);

  useEffect(() => {
    let key: SnackbarKey | null = null;
    (async () => {
      try {
        const project = ProjectFromAPI(
          await ProjectService.getProject(props.match.params.projectUrl)
        );
        setProject(project);
        setJiraServer(project.jira);
        setIsLoading(false);
      } catch (e) {
        key = enqueueSnackbar("Fout bij project laden", {
          autoHideDuration: null,
        });
      }
    })();

    return () => {
      closeSnackbar();
    };
  }, [refreshIndex]);

  const disabled = isLoading || isSubmitting;

  async function save(e: FormEvent) {
    e.preventDefault();
    try {
      if (project !== null) {
        setIsSubmitting(true);
        const { hours: _, ...requestBody } = ProjectToAPI(project);
        const updatedProject = ProjectFromAPI(
          await ProjectService.updateProject(project.url, requestBody)
        );
        setProject({ ...updatedProject, hours: project.hours });
        setJiraServer(updatedProject.jira);
        enqueueSnackbar("Wijzigingen opgeslagen", {
          variant: "success",
          autoHideDuration: 800,
        });
      }
    } catch (e) {
      enqueueSnackbar(`Fout bij opslaan: ${e.body?.error || e.message}`, {
        variant: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  }

  async function _delete() {
    try {
      if (project !== null) {
        if (
          !confirm(
            `Dit verwijdert ook alle geregistreerde uren van ${project.name}, weet je het zeker?`
          )
        )
          return;

        setIsSubmitting(true);
        await ProjectService.deleteProject(project.url);
        enqueueSnackbar(`Project ${project.name} verwijderd`, {
          variant: "success",
        });
        process.nextTick(() => {
          props.history.replace("/");
        });
      }
    } catch (e) {
      enqueueSnackbar(`Fout bij verwijderen: ${e.body?.error || e.message}`, {
        variant: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <React.Fragment>
      <h1>{project?.name}</h1>

      {project !== null ? (
        <React.Fragment>
          <h2>Factuur maken</h2>

          <Paper
            className={clsx(classes.container, isLoading && classes.loading)}
          >
            <CreateInvoice project={project} />
          </Paper>
        </React.Fragment>
      ) : null}

      {jiraServer && project !== null ? (
        <React.Fragment>
          <h2>Jira synchroniseren</h2>

          <Paper
            className={clsx(classes.container, isLoading && classes.loading)}
          >
            <JiraSynchronization
              disabled={disabled}
              project={project}
              onRefresh={() => setRefreshIndex((i) => i + 1)}
            />
          </Paper>
        </React.Fragment>
      ) : null}

      <h2>Open uren</h2>
      <OpenHours
        jira={jiraServer}
        hours={project?.hours ?? []}
        projectUrl={project?.url ?? ""}
        setHours={(hours) => {
          if (project !== null) setProject({ ...project, hours: hours });
        }}
      />

      <h2>Wijzigen</h2>
      <Paper className={clsx(classes.container, isLoading && classes.loading)}>
        <form onSubmit={save}>
          <Grid justify="center" container>
            <Grid md={8} item>
              <ProjectForm
                project={project}
                onChange={setProject}
                disabled={disabled}
              />

              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={disabled}
                className={classes.button}
                startIcon={<SaveIcon />}
              >
                Opslaan
              </Button>
              <Button
                color="primary"
                variant="contained"
                disabled={disabled}
                className={`${classes.destructive} ${classes.button}`}
                startIcon={<DeleteIcon />}
                onClick={_delete}
              >
                Verwijderen
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </React.Fragment>
  );
}
