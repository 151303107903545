/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiToken } from '../models/ApiToken';
import type { AuthenticateInput } from '../models/AuthenticateInput';
import { request as __request } from '../core/request';

export class ApiService {

    /**
     * @param requestBody
     * @returns ApiToken OK
     * @throws ApiError
     */
    public static async authenticate(
        requestBody?: AuthenticateInput,
    ): Promise<ApiToken> {
        const result = await __request({
            method: 'POST',
            path: `/api/authenticate`,
            body: requestBody,
        });
        return result.body;
    }

}