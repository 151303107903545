import React, { ReactNode, useState, useEffect, useMemo } from "react";
import { ApiService, OpenAPI } from "../../api";

interface UserSession {
  token: string | null;
  login: (password: string) => Promise<void>;
  logout: () => void;
}
OpenAPI.TOKEN = async () => window.localStorage.getItem("token") || "";

export default function useUserSession(): UserSession {
  const [token, setToken] = useState(() =>
    window.localStorage.getItem("token")
  );

  async function login(password: string): Promise<void> {
    const result = await ApiService.authenticate({ password });
    setToken(result.token);
    window.localStorage.setItem("token", result.token);
  }

  function logout(): void {
    window.localStorage.removeItem("token");
    setToken(null);
  }

  return { token, login, logout };
}
