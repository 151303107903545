/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateProjectInput } from '../models/CreateProjectInput';
import type { Project } from '../models/Project';
import type { ProjectAnnotated } from '../models/ProjectAnnotated';
import { request as __request } from '../core/request';

export class ProjectsService {

    /**
     * @returns ProjectAnnotated OK
     * @throws ApiError
     */
    public static async listProjects(): Promise<Array<ProjectAnnotated>> {
        const result = await __request({
            method: 'GET',
            path: `/api/projects`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns Project Created
     * @throws ApiError
     */
    public static async createProject(
        requestBody?: CreateProjectInput,
    ): Promise<Project> {
        const result = await __request({
            method: 'POST',
            path: `/api/projects`,
            body: requestBody,
        });
        return result.body;
    }

}