import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { ApiError, ProjectAnnotated, ProjectsService } from "../../api";
import * as classes from "./Projects.less";
import { Skeleton } from "@material-ui/lab";
import Add from "@material-ui/icons/Add";
import usePromise from "./usePromise";
import { useSnackbar } from "notistack";
import { Link, useHistory, useLocation } from "react-router-dom";
import clsx from "clsx";

export default function Projects(): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [projects, { error }] = usePromise<ProjectAnnotated[], null>({
    initial: null,
    promise: async () => await ProjectsService.listProjects(),
  });

  useEffect(() => {
    if (error !== null) {
      enqueueSnackbar(
        `Fout: ${(error as ApiError).body?.error || error.message}`,
        {
          variant: "error",
        }
      );
    }
  }, [error]);
  return (
    <React.Fragment>
      <Link to="/new">
        <Button
          className={classes.add}
          variant="contained"
          color="primary"
          startIcon={<Add />}
        >
          Nieuw project
        </Button>
      </Link>
      <h2>Projecten</h2>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Project</TableCell>
              <TableCell>Laatste factuur</TableCell>
              <TableCell>Open uren</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projects === null ? (
              <TableRow>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
              </TableRow>
            ) : (
              projects.map((project) => {
                const to = { pathname: `/${project.url}`, state: { project } };
                return (
                  <TableRow
                    key={project.url}
                    className={clsx(
                      classes.row,
                      !project.visible && classes.inactive
                    )}
                  >
                    <TableCell>
                      <Link to={to}>{project.name}</Link>
                    </TableCell>
                    <TableCell>
                      <Link to={to}>
                        {project.last_invoice
                          ? new Date(project.last_invoice).toLocaleDateString(
                              undefined,
                              {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              }
                            )
                          : "-"}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link to={to}>
                        {project.uninvoiced_hours !== 0
                          ? `${project.uninvoiced_hours.toFixed(1)} (€${(
                              project.uninvoiced_hours * project.rate
                            ).toFixed(2)})`
                          : "-"}
                      </Link>
                    </TableCell>
                    {/*<TableCell>*/}
                    {/*<TextField*/}
                    {/*  label="Start"*/}
                    {/*  type="date"*/}
                    {/*  InputLabelProps={{ shrink: true }}*/}
                    {/*/>*/}
                    {/*<TextField*/}
                    {/*  label="Einde"*/}
                    {/*  type="date"*/}
                    {/*  InputLabelProps={{ shrink: true }}*/}
                    {/*/>*/}
                    {/*<Button*/}
                    {/*  style={{ margin: 12 }}*/}
                    {/*  variant="contained"*/}
                    {/*  color="primary"*/}
                    {/*>*/}
                    {/*  Maak factuur*/}
                    {/*</Button>*/}
                    {/*<Button>*/}
                    {/*  <img src={digitaleFactuurLogo} />*/}
                    {/*</Button>*/}
                    {/*</TableCell>*/}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}
