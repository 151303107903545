/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateHourInput } from '../models/CreateHourInput';
import type { Hour } from '../models/Hour';
import { request as __request } from '../core/request';

export class HoursService {

    /**
     * @returns Hour OK
     * @throws ApiError
     */
    public static async listHours(): Promise<Array<Hour>> {
        const result = await __request({
            method: 'GET',
            path: `/api/projects/${url}/hours`,
        });
        return result.body;
    }

    /**
     * @param url
     * @param requestBody
     * @returns Hour Created
     * @throws ApiError
     */
    public static async createHour(
        url: string,
        requestBody?: CreateHourInput,
    ): Promise<Hour> {
        const result = await __request({
            method: 'POST',
            path: `/api/projects/${url}/hours`,
            body: requestBody,
        });
        return result.body;
    }

}