/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiInvoiceOutput } from '../models/ApiInvoiceOutput';
import type { InvoiceInput } from '../models/InvoiceInput';
import type { Project } from '../models/Project';
import type { UpdateProjectInput } from '../models/UpdateProjectInput';
import { request as __request } from '../core/request';

export class ProjectService {

    /**
     * @param url
     * @returns Project OK
     * @throws ApiError
     */
    public static async getProject(
        url: string,
    ): Promise<Project> {
        const result = await __request({
            method: 'GET',
            path: `/api/projects/${url}`,
        });
        return result.body;
    }

    /**
     * @param url
     * @param requestBody
     * @returns Project OK
     * @throws ApiError
     */
    public static async updateProject(
        url: string,
        requestBody?: UpdateProjectInput,
    ): Promise<Project> {
        const result = await __request({
            method: 'PUT',
            path: `/api/projects/${url}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param url
     * @returns string OK
     * @throws ApiError
     */
    public static async deleteProject(
        url: string,
    ): Promise<string> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/projects/${url}`,
        });
        return result.body;
    }

    /**
     * @param url
     * @param requestBody
     * @returns ApiInvoiceOutput OK
     * @throws ApiError
     */
    public static async invoice(
        url: string,
        requestBody?: InvoiceInput,
    ): Promise<ApiInvoiceOutput> {
        const result = await __request({
            method: 'POST',
            path: `/api/projects/${url}/invoice`,
            body: requestBody,
        });
        return result.body;
    }

}