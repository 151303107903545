/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Hour } from '../models/Hour';
import type { UpdateHourInput } from '../models/UpdateHourInput';
import { request as __request } from '../core/request';

export class HourService {

    /**
     * @param hourId
     * @param url
     * @param requestBody
     * @returns Hour OK
     * @throws ApiError
     */
    public static async updateHour(
        hourId: string,
        url: string,
        requestBody?: UpdateHourInput,
    ): Promise<Hour> {
        const result = await __request({
            method: 'PUT',
            path: `/api/projects/${url}/hours/${hourId}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param hourId
     * @param url
     * @returns string OK
     * @throws ApiError
     */
    public static async deleteHour(
        hourId: string,
        url: string,
    ): Promise<string> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/projects/${url}/hours/${hourId}`,
        });
        return result.body;
    }

}