import React, { useState } from "react";
import SyncIcon from "@material-ui/icons/Sync";
import { useSnackbar } from "notistack";
import { Button, TextField } from "@material-ui/core";
import * as classes from "./JiraSynchronization.less";
import useUserSession from "./useUserSession";
import { Project } from "../models/Project";

interface JiraSynchronizationProps {
  project: Project;
  onRefresh: () => void;
  disabled: boolean;
}

export default function JiraSynchronization({
  project,
  onRefresh,
  disabled,
}: JiraSynchronizationProps): JSX.Element {
  const [output, setOutput] = useState("");
  const [start, setStart] = useState(() => {
    const date = project.lastInvoice ?? new Date();
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), 1));
  });
  const [synchronizing, setSynchronizing] = useState(false);
  const snackbar = useSnackbar();
  const userSession = useUserSession();

  function synchronize() {
    setSynchronizing(true);
    setOutput("");

    const socket = new WebSocket(
      `${window.location.protocol === "https:" ? "wss:" : "ws:"}//${
        window.location.host
      }/api/projects/${
        project.url
      }/synchronize?token=${userSession.token!}&start=${start.toISOString()}`
    );
    socket.addEventListener("error", (e) =>
      snackbar.enqueueSnackbar("Websocket error", { variant: "error" })
    );
    socket.addEventListener("close", (e) => {
      setSynchronizing(false);
      onRefresh();
    });
    socket.addEventListener("message", (e) => {
      setOutput((o) => `${o}\n${e.data}`.trim());
    });
  }

  return (
    <div>
      <TextField
        disabled={disabled}
        label="Vanaf"
        type="date"
        InputLabelProps={{ shrink: true }}
        onChange={(e) => setStart(new Date(e.target.value))}
        value={start.toISOString().slice(0, 10)}
      />
      <div className={classes.output}>{output}</div>
      <Button
        disabled={disabled || synchronizing}
        onClick={() => {
          synchronize();
        }}
        color="primary"
        variant="contained"
      >
        <SyncIcon className={synchronizing ? classes.rotating : null} />
        Synchroniseren
      </Button>
    </div>
  );
}
