import React, { FormEvent, useState } from "react";
import { Button, Paper, TextField } from "@material-ui/core";
import * as classes from "./Login.less";
import { RouteComponentProps } from "react-router";
import { useSnackbar } from "notistack";

export interface LoginProps
  extends RouteComponentProps<{}, {}, { referrer: string }> {
  login: (password: string) => Promise<void>;
}

export default function Login({
  history,
  location,
  login,
}: LoginProps): JSX.Element {
  const [password, setPassword] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  async function submit(e: FormEvent) {
    e.preventDefault();
    try {
      setSubmitting(true);
      await login(password);
      process.nextTick(() => {
        history.replace(location.state.referrer);
      });
    } catch (e) {
      enqueueSnackbar(`Fout bij inloggen: ${e.body?.error || e.message}`, {
        variant: "error",
      });
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Paper className={classes.login}>
      <h2>Login</h2>
      <form onSubmit={submit}>
        <TextField
          disabled={submitting}
          label="password"
          autoFocus={true}
          type="password"
          fullWidth
          required
          onChange={(e) => setPassword(e.target.value)}
          inputProps={{ shrink: "true" }}
        />
        <Button
          color="primary"
          variant="contained"
          type="submit"
          disabled={submitting}
        >
          Login
        </Button>
      </form>
    </Paper>
  );
}
