import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@material-ui/core";
import React, { FormEvent, useEffect, useState } from "react";
import { HourService, HoursService } from "../../api";
import * as classes from "./OpenHours.less";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import HelpIcon from "@material-ui/icons/Help";
import { useSnackbar } from "notistack";
import jiraLogo from "url:../img/jira-teamcoda.png";
import { Hour, HourFromAPI, HourToAPI } from "../models/Hour";

interface HourDialogProps {
  hour: Hour | null;
  setHour: (hour: Hour) => void;
  projectUrl: string;
  onClose: () => void;
  jira: boolean;
}

function HourDialog({
  hour: serverHour,
  setHour: setServerHour,
  projectUrl,
  onClose,
  jira,
}: HourDialogProps): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [disabled, setDisabled] = useState(false);
  const [hour, setHour] = useState<Hour>({
    description: "",
    duration: 0,
    id: "",
    start: new Date(),
    jiraWorklogId: null,
    jiraIssueKey: null,
  });
  const [durationHours, setDurationHours] = useState(0);
  const [durationMinutes, setDurationMinutes] = useState(0);

  useEffect(() => {
    if (serverHour !== null) {
      setDisabled(false);
      setHour(serverHour);
      setDurationHours(Math.floor(serverHour.duration / 3600));
      setDurationMinutes(Math.floor(serverHour.duration / 60) % 60);
    }
  }, [serverHour]);

  useEffect(() => {
    if (!isNaN(durationHours) && !isNaN(durationMinutes)) {
      setHour({
        ...hour,
        duration: durationHours * 3600 + durationMinutes * 60,
      });
    }
  }, [durationHours, durationMinutes]);

  async function submitForm(e: FormEvent) {
    e.preventDefault();
    try {
      setDisabled(true);
      const updatedHour = HourFromAPI(
        await HourService.updateHour(hour.id, projectUrl, HourToAPI(hour))
      );
      setServerHour(updatedHour);
      enqueueSnackbar(`Uur geüpdatet`, {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar(`Fout bij updaten: ${e.body?.error || e.message}`, {
        variant: "error",
      });
      setDisabled(false);
    }
  }

  return (
    <Dialog open={serverHour !== null} onClose={onClose}>
      <form onSubmit={submitForm}>
        <DialogTitle>Uur bewerken</DialogTitle>
        <DialogContent>
          <TextField
            disabled={disabled}
            label="Start"
            type="datetime-local"
            required
            InputLabelProps={{ shrink: true }}
            className={classes.input}
            onChange={(e) => {
              if (e.target.value !== "") {
                setHour({ ...hour, start: new Date(e.target.value) });
              }
            }}
            value={hour.start.toDatetimeLocalString()}
          />
          <TextField
            disabled={disabled}
            label="Uren"
            required
            type="number"
            onChange={(e) => setDurationHours(Number.parseInt(e.target.value))}
            value={durationHours}
            style={{ marginRight: 8 }}
          />
          <TextField
            disabled={disabled}
            label="Minuten"
            required
            type="number"
            onChange={(e) => {
              setDurationMinutes(Number.parseInt(e.target.value));
            }}
            value={durationMinutes ?? 0}
          />

          <TextField
            disabled={disabled}
            label="Beschrijving"
            className={classes.input}
            onChange={(e) => {
              setHour({ ...hour, description: e.target.value });
            }}
            value={hour.description}
          />

          {jira ? (
            <React.Fragment>
              <TextField
                disabled={disabled}
                label="Jira issue key"
                className={classes.input}
                onChange={(e) => {
                  setHour({
                    ...hour,
                    jiraIssueKey: e.target.value === "" ? null : e.target.value,
                  });
                }}
                value={hour.jiraIssueKey ?? ""}
              />
              <TextField
                disabled={disabled}
                label="Jira worklog id"
                type="number"
                className={classes.input}
                onChange={(e) => {
                  setHour({
                    ...hour,
                    jiraWorklogId:
                      e.target.value === ""
                        ? null
                        : Number.parseInt(e.target.value),
                  });
                }}
                value={hour.jiraWorklogId ?? ""}
              />
            </React.Fragment>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={disabled}
          >
            Opslaan
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

interface HourRowProps {
  jira: boolean;
  hour: Hour;
  onEditHour: () => void;
  onDeleteHour: () => void;
}

function HourRow({
  jira,
  hour,
  onEditHour,
  onDeleteHour,
}: HourRowProps): JSX.Element {
  function hourTimeFormat(hour: Hour): JSX.Element {
    const start = new Date(hour.start);
    const end = new Date(start.getTime() + hour.duration * 1000);

    const options: Intl.DateTimeFormatOptions = {
      weekday: "long",
      day: "numeric",
      month: "long",
    };
    if (start.getFullYear() !== new Date().getFullYear()) {
      options.year = "numeric";
    }
    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "2-digit",
    };
    const date = start.toLocaleDateString(undefined, options);
    const startTime = start.toLocaleTimeString(undefined, timeOptions);
    const endTime = end.toLocaleTimeString(undefined, timeOptions);
    return (
      <React.Fragment>
        {date}{" "}
        <span className={classes.time}>
          {startTime} - {endTime}
        </span>
      </React.Fragment>
    );
  }

  return (
    <TableRow>
      <TableCell>{hourTimeFormat(hour)}</TableCell>
      <TableCell>{hour.description}</TableCell>
      <TableCell>
        {jira ? (
          <Button
            component="a"
            href={`https://digibri.atlassian.net/browse/${hour.jiraIssueKey}?focusedWorklogId=${hour.jiraWorklogId}`}
          >
            <img src={jiraLogo} alt="JIRA" />
          </Button>
        ) : null}
        <Button onClick={onEditHour}>
          <EditIcon />
        </Button>
        <Button onClick={onDeleteHour}>
          <DeleteIcon />
        </Button>
      </TableCell>
    </TableRow>
  );
}

interface OpenHoursProps {
  jira: boolean;
  hours: Array<Hour> | null;
  projectUrl: string;
  setHours: (hours: Array<Hour>) => void;
}

export default function OpenHours({
  jira,
  hours,
  projectUrl,
  setHours,
}: OpenHoursProps): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [quicksaveText, setQuicksaveText] = useState("");

  async function hourQuickSave(e: FormEvent) {
    e.preventDefault();
    const months = [
      "januari",
      "februari",
      "maart",
      "april",
      "mei",
      "juni",
      "juli",
      "augustus",
      "september",
      "oktober",
      "november",
      "december",
    ];
    const hoursMatch = quicksaveText.match(/([0-9.]+) uur/i);
    const dateMatch = quicksaveText.match(
      new RegExp(`([0-9]+) (${months.join("|")}) ?(2[0-9]{3})?.*uur`, "i")
    );
    const timeMatch = quicksaveText.match(/([0-9]{1,2}):([0-9]{2}).*uur/);
    const relativeDateMatch = quicksaveText.match(
      /(gisteren|eergisteren|morgen).*uur/i
    );
    const descriptionMatch = quicksaveText.match(/uur (.*)/);

    if (hoursMatch === null) {
      enqueueSnackbar("Ongeldig formaat", { variant: "error" });
      return;
    }

    const durationHours = Number.parseFloat(hoursMatch[1]);
    let start = new Date(new Date().getTime() - durationHours * 3600 * 1000); // Default start time relative to now
    let description = "";

    if (dateMatch !== null) {
      start.setDate(Number.parseInt(dateMatch[1]));
      start.setMonth(months.findIndex((m) => m === dateMatch[2]));
      start.setFullYear(
        dateMatch[3] !== undefined
          ? Number.parseInt(dateMatch[3])
          : start.getFullYear()
      );
    }
    if (timeMatch !== null) {
      start.setHours(
        Number.parseInt(timeMatch[1]),
        Number.parseInt(timeMatch[2]),
        0,
        0
      );
    }
    if (relativeDateMatch !== null) {
      const DAY = 24 * 60 * 60 * 1000;
      if (relativeDateMatch[1] === "gisteren") {
        start.setTime(start.getTime() - DAY);
      } else if (relativeDateMatch[1] === "eergisteren") {
        start.setTime(start.getTime() - DAY * 2);
      } else if (relativeDateMatch[1] === "morgen") {
        start.setTime(start.getTime() + DAY);
      }
      start.setHours(9, 0, 0, 0);
    }
    if (descriptionMatch !== null) {
      description = descriptionMatch[1];
    }

    try {
      const newHour = HourFromAPI(
        await HoursService.createHour(projectUrl, {
          description,
          duration: durationHours * 3600,
          start: start.toISOString(),
        })
      );
      if (hours !== null) {
        setHours([newHour, ...hours]);
      }
      setQuicksaveText("");
      enqueueSnackbar(`Uur toegevoegd`, {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar(`Fout bij aanmaken: ${e.body?.error || e.message}`, {
        variant: "error",
      });
    }
  }

  async function deleteHour(hour: Hour) {
    if (!confirm("Weet je het zeker dat je deze wil verwijderen?")) return;
    try {
      await HourService.deleteHour(hour.id, projectUrl);
      if (hours !== null) {
        setHours(hours.filter((h) => h.id !== hour.id));
      }
      enqueueSnackbar("Uur verwijderd", { variant: "success" });
    } catch (e) {
      enqueueSnackbar(`Fout bij verwijderen: ${e.body?.error || e.message}`, {
        variant: "error",
      });
    }
  }

  const [editingHour, setEditingHour] = useState<Hour | null>(null);

  return (
    <React.Fragment>
      <HourDialog
        hour={editingHour}
        setHour={(updatedHour) => {
          setEditingHour(null);
          if (hours !== null) {
            setHours(
              hours.map((hour) =>
                hour.id === updatedHour.id ? updatedHour : hour
              )
            );
          }
        }}
        projectUrl={projectUrl}
        onClose={() => setEditingHour(null)}
        jira={jira}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Tijd</TableCell>
              <TableCell>Omschrijving</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={2}>
                <form onSubmit={hourQuickSave} style={{ display: "flex" }}>
                  <TextField
                    variant="outlined"
                    autoFocus
                    inputProps={{ pattern: ".*[0-9.]+ uur.*" }}
                    style={{ flex: 1 }}
                    value={quicksaveText}
                    onChange={(e) => setQuicksaveText(e.target.value)}
                  />
                  <Tooltip
                    title={
                      <React.Fragment>
                        2 uur
                        <br />
                        gisteren 10:15 3 uur
                        <br />
                        23 mei 8.5 uur Aan droogtescan gewerkt
                      </React.Fragment>
                    }
                  >
                    <HelpIcon />
                  </Tooltip>
                </form>
              </TableCell>
              <TableCell />
            </TableRow>
            {(hours ?? []).map((hour) => (
              <HourRow
                key={hour.id}
                hour={hour}
                jira={jira}
                onEditHour={() => setEditingHour(hour)}
                onDeleteHour={() => deleteHour(hour)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}
