import { Hour, HourFromAPI, HourToAPI } from "./Hour";
import { Project as ProjectApi } from "../../api";

export type Project = {
  url: string;
  digitalefactuurKlantId: number;
  hours: Array<Hour>;
  jira: boolean;
  lastInvoice: Date | null;
  name: string;
  rate: number;
  visible: boolean;
};

export function ProjectToAPI(p: Project): ProjectApi {
  return {
    url: p.url,
    jira: p.jira,
    visible: p.visible,
    name: p.name,
    rate: p.rate,
    digitalefactuur_klant_id: p.digitalefactuurKlantId,
    hours: p.hours.map(HourToAPI),
    last_invoice: p.lastInvoice?.toISOString() ?? null,
  };
}

export function ProjectFromAPI(p: ProjectApi): Project {
  return {
    url: p.url,
    rate: p.rate,
    name: p.name,
    digitalefactuurKlantId: p.digitalefactuur_klant_id,
    hours: p.hours ? p.hours.map(HourFromAPI) : [],
    lastInvoice: p.last_invoice ? new Date(Date.parse(p.last_invoice)) : null,
    jira: p.jira ?? false,
    visible: p.visible ?? false,
  };
}
