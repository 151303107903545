import { Hour as ApiHour } from "../../api";

export type Hour = {
  id: string;
  description: string;
  duration: number;
  jiraIssueKey: string | null;
  jiraWorklogId: number | null;
  start: Date;
};

export function HourToAPI(h: Hour): ApiHour {
  return {
    id: h.id,
    duration: h.duration,
    description: h.description,
    jira_issue_key: h.jiraIssueKey,
    jira_worklog_id: h.jiraWorklogId,
    start: h.start.toISOString(),
  };
}

export function HourFromAPI(h: ApiHour): Hour {
  return {
    id: h.id ?? "",
    duration: h.duration,
    description: h.description ?? "",
    jiraIssueKey: h.jira_issue_key ?? null,
    jiraWorklogId: h.jira_worklog_id ?? null,
    start: new Date(Date.parse(h.start)),
  };
}
