import { Button, Grid, Paper } from "@material-ui/core";
import React, { FormEvent, useState } from "react";
import { ProjectsService } from "../../api";
import * as classes from "./Project.less";
import ProjectForm from "./ProjectForm";
import SaveIcon from "@material-ui/icons/Save";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { Project } from "../models/Project";

export default function ProjectNew(): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [project, setProject] = useState<Project>({
    digitalefactuurKlantId: 0,
    hours: [],
    lastInvoice: null,
    jira: false,
    name: "",
    rate: 80,
    url: "",
    visible: true,
  });

  async function create(e: FormEvent) {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      await ProjectsService.createProject({
        digitalefactuur_klant_id: project.digitalefactuurKlantId,
        jira: project.jira,
        last_invoice: project?.lastInvoice?.toLocaleString() ?? null,
        name: project.name,
        rate: project.rate,
        url: project.url,
        visible: project.visible,
      });
      enqueueSnackbar("Project aangemaakt", {
        variant: "success",
      });
      history.replace(`/${project.url}`);
    } catch (e) {
      enqueueSnackbar(`Fout bij aanmaken: ${e.body?.error || e.message}`, {
        variant: "error",
      });
      setIsSubmitting(false);
    }
  }

  return (
    <React.Fragment>
      <h2>Nieuw project</h2>
      <Paper className={classes.container}>
        <form onSubmit={create}>
          <Grid justify="center" container>
            <Grid md={8} item>
              <ProjectForm
                project={project}
                onChange={setProject}
                disabled={isSubmitting}
              />

              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={isSubmitting}
                className={classes.button}
                startIcon={<SaveIcon />}
              >
                Aanmaken
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </React.Fragment>
  );
}
