import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import React from "react";
import * as classes from "./ProjectForm.less";
import { Project } from "../models/Project";

interface ProjectFormProps {
  project: Project | null;
  onChange: (p: Project) => void;
  disabled: boolean;
}

export default function ProjectForm({
  project,
  onChange,
  disabled,
}: ProjectFormProps): JSX.Element {
  return (
    <React.Fragment>
      <TextField
        disabled={disabled}
        label="Naam"
        required
        className={classes.input}
        onChange={(e) => {
          if (project !== null) onChange({ ...project, name: e.target.value });
        }}
        value={project?.name || ""}
      />
      <TextField
        disabled={disabled}
        label="URL"
        required
        className={classes.input}
        inputProps={{
          maxLength: 10,
        }}
        onChange={(e) => {
          if (project !== null) onChange({ ...project, url: e.target.value });
        }}
        value={project?.url || ""}
      />
      <FormControlLabel
        className={classes.input}
        control={
          <Checkbox
            disabled={disabled}
            checked={project !== null ? project.visible : true}
            onChange={(e) => {
              if (project !== null)
                onChange({ ...project, visible: e.target.checked });
            }}
          />
        }
        label="Agenda zichtbaar"
      />
      <TextField
        disabled={disabled}
        label="Uurtarief"
        type="number"
        className={classes.input}
        required
        onChange={(e) => {
          if (project !== null)
            onChange({
              ...project,
              rate: Number.parseInt(e.target.value),
            });
        }}
        value={project?.rate || ""}
      />
      <TextField
        disabled={disabled}
        label="Laatste factuur"
        type="datetime-local"
        className={classes.input}
        InputLabelProps={{ shrink: true }}
        onChange={(e) => {
          if (project !== null)
            onChange({
              ...project,
              lastInvoice:
                e.target.value == "" ? null : new Date(e.target.value),
            });
        }}
        value={project?.lastInvoice?.toDatetimeLocalString() ?? ""}
      />
      <TextField
        disabled={disabled}
        label="Digitale Factuur klant id"
        type="number"
        className={classes.input}
        required
        onChange={(e) => {
          if (project !== null)
            onChange({
              ...project,
              digitalefactuurKlantId: Number.parseInt(e.target.value),
            });
        }}
        value={project?.digitalefactuurKlantId ?? ""}
      />
      <FormControlLabel
        className={classes.input}
        control={
          <Checkbox
            disabled={disabled}
            checked={project?.jira === true}
            onChange={(e) => {
              if (project !== null)
                onChange({ ...project, jira: e.target.checked });
            }}
          />
        }
        label="Jira"
      />
    </React.Fragment>
  );
}
