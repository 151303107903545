declare global {
  interface Date {
    toDatetimeLocalString: () => string;
  }
}

(function () {
  Date.prototype.toDatetimeLocalString = function (this: Date) {
    return this.toLocaleString("sv-SE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).replace(" ", "T");
  };
})();

export {};
