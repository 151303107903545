import * as classes from "./App.less";
import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { Button } from "@material-ui/core";
import useUserSession from "./useUserSession";
import Login from "./Login";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import Projects from "./Projects";
import ProjectDetail from "./ProjectDetail";
import ProjectNew from "./ProjectNew";

export default function App() {
  const { login, logout, token } = useUserSession();

  return (
    <SnackbarProvider maxSnack={10}>
      <Router>
        {/* Top bar */}
        <AppBar position="static">
          <Toolbar>
            <Typography
              component="h1"
              variant="h6"
              className={classes.header}
              noWrap
            >
              <Link to="/">Uren administratie</Link>
            </Typography>
            {token !== null ? (
              <Button
                style={{ color: "white", position: "absolute", right: 16 }}
                onClick={logout}
              >
                Logout
              </Button>
            ) : null}
          </Toolbar>
        </AppBar>

        {/* Main content */}
        <main>
          <Container maxWidth="md">
            <Switch>
              <Route
                path="/login"
                render={(props: RouteComponentProps<{}, {}, any>) => (
                  <Login {...props} login={login} />
                )}
              />
              <Route>
                {token === null ? (
                  <Redirect
                    to={{
                      pathname: "/login",
                      state: { referrer: window.location.pathname },
                    }}
                    push={false}
                  />
                ) : (
                  <Switch>
                    <Route path="/" exact component={Projects} />
                    <Route path="/new" component={ProjectNew} />
                    <Route path="/:projectUrl" component={ProjectDetail} />
                  </Switch>
                )}
              </Route>
            </Switch>
          </Container>
        </main>
      </Router>
    </SnackbarProvider>
  );
}
